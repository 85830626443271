import request from '@/utils/request'

// 管理员加入
export function ajoinvdeo(data) {
    return request({
        url: '/ajoinvdeo',
        method: 'get',
        params: data,
    })
}
//   管理员离开
export function alevavideo(data) {
    return request({
        url: '/alevavideo',
        method: 'get',
        params: data,
    })
}

// 用户加入
export function ujoinvideo(data) {
    return request({
        url: '/ujoinvideo',
        method: 'get',
        params: data,
    })
}
